<template>
    <section>
        <!-- Reading fiat config first -->
        <loading-indicator v-if="!fiatConfig" />
        <form v-else class="form-horizontal" method="post" action="javascript:" @submit="doConfirm">
            <div v-if="fiatConfig.isWithdrawWindowOpen === false">
                <div class="alert alert-info">
                    {{ $t('withdraw.not_time') }}
                </div>
            </div>
            <div class="form-group row">
                <div class="col">
                    <label class="form-label">{{ $t('withdraw.label_amount') }} </label>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="input-group">
                                <input type="text" name="Amount" v-model="amount" class="form-control" data-val="true" data-val-required="*" data-val-number="*" data-val-range="*" v-bind:data-val-range-min="fiatConfig.minWithdrawAmount" v-bind:data-val-range-max="maxAmountPerOrder" />
                                <div class="input-group-text">{{ currencySymbol }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="help-block">
                        <div>
                            {{ $t('withdraw.label_available_amount') }} <span>{{ fiatConfig.maxAvailableAmount.toFixed(2) }} {{ currencySymbol }}</span>
                        </div>
                        <div>
                            <span v-if="fiatConfig.maxWithdrawAmount < 0x7fffffff">
                                {{
                                    $t('withdraw.label_amount_range', {
                                        min: fiatConfig.minWithdrawAmount,
                                        max: fiatConfig.maxWithdrawAmount,
                                        currency_symbol: currencySymbol
                                    })
                                }}
                            </span>
                            <span v-else>{{ $t('withdraw.label_min_amount', { min: fiatConfig.minWithdrawAmount, currency_symbol: currencySymbol }) }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row" v-if="fiatConfig.requiresWithdrawPassword === true">
                <div class="col">
                    <label class="form-label">{{ $t('withdraw.label_withdraw_pwd') }} </label>
                    <div class="row">
                        <div class="col-md-4">
                            <input type="password" name="WithdrawPassword" v-model="password" class="form-control" autocomplete="off" data-val="true" data-val-required="*" data-val-regex-pattern="^\d{6}$" data-val-regex="*" data-val-maxlength="*" data-val-maxlength-max="6" data-val-minlength="*" data-val-minlength-min="6" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <div class="col col-md-4">
                    <button type="submit" :disabled="fiatConfig.isWithdrawWindowOpen === false" class="btn btn-submit">{{ $t('general.submit') }}</button>
                </div>
            </div>
        </form>

        <!-- A confirmation modal -->
        <div ref="confirmModal" class="modal fade" tabindex="-1" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <form action="javascript:void(0);" method="post" @submit="doSubmit">
                        <!-- modal header -->
                        <div class="modal-header">
                            <h4 class="modal-title">{{ $t('general.confirm') }}</h4>
                            <button type="button" class="close" data-bs-dismiss="modal" aria-hidden="true">&times;</button>
                        </div>

                        <!-- modal body -->
                        <div class="modal-body">
                            <input type="hidden" name="Amount" v-model="amount" />
                            <input type="hidden" name="WithdrawPassword" v-model="password" />
                            <div class="form-group row">
                                <div class="col">
                                    <label class="form-label"> {{ $t('withdraw.label_amount') }} </label>
                                    <div class="row">
                                        <div class="col-md-4">{{ amount }} {{ currencySymbol }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- modal footer -->
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-bs-dismiss="modal">{{ $t('general.cancel') }}</button>
                            <button type="submit" class="btn btn-primary" :class="{ 'btn-loading': submitting }" :disabled="submitting">{{ $t('general.confirm') }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Modal } from 'bootstrap';

export default {
    props: ['fiatConfig'],
    data() {
        return {
            // Config for fiat withdraws: fiat withdraws are only for USDT.

            amount: '',
            password: '',

            submitting: false,

            // The popup confirmation modal.
            popupModal: null
        };
    },

    computed: {
        maxAmountPerOrder: function () {
            const config = this.fiatConfig;
            return Math.min(config.maxWithdrawAmount, config.maxAvailableAmount);
        }
    },

    mounted() {
        this.popupModal = new Modal(this.$refs.confirmModal);
    },

    beforeDestroy() {
        const m = this.popupModal;
        if (m) {
            m.dispose();
        }
    },

    methods: {
        doConfirm: function (e) {
            const paymentSource = this.fiatConfig.paymentSource;
            if (!paymentSource || true !== /^[a-zA-Z0-0_\-]+$/gi.test(paymentSource)) throw new Error('Requires a valid payment source.');

            const frm = $(e.target);
            if (frm.valid()) {
                this.submitting = false;
                // $(this.$refs.confirmModal).modal('show');
                this.popupModal.show();
            } else {
                // Is it because of invalid withdraw amount
                const str = $.trim('  ' + this.amount);
                if (str.length > 0) {
                    const amt = this.amount * 1;
                    if (!isNaN(amt)) {
                        if (amt > this.maxAmountPerOrder) {
                            $.top_error(this.$t('withdraw.err_amount_exceeded'));
                        } else if (amt < this.fiatConfig.minWithdrawAmount) {
                            $.top_error(this.$t('withdraw.err_too_small_amount'));
                        }
                    }
                }
            }
        },

        doSubmit: function (e) {
            const paymentSource = this.fiatConfig.paymentSource;
            if (!paymentSource || true !== /^[a-zA-Z0-0_\-]+$/gi.test(paymentSource)) throw new Error('Requires a valid payment source.');

            const self = this;
            const frm = $(e.target);
            if (frm.valid()) {
                const data = frm.serializeAsJson();

                // Submit to server now.
                this.submitting = true;
                $.callPostApi(self, '/api/v1/' + paymentSource + '/withdraw', data)
                    .then(function (json) {
                        if (json && json.errcode === 0) {
                            $.top_alert(json.errmsg || self.$t('general.operation_succeeded'));
                        } else {
                            $.top_error(json.errmsg || self.$t('general.http_error'));
                        }

                        // close the modal
                        // $(self.$refs.confirmModal).modal('hide');
                        self.popupModal.hide();

                        // Fire an event to notify the completion of a withdraw order.
                        Vue.nextTick(() => {
                            $.resetValidators();
                        });
                    })
                    .catch(function () {
                        $.top_error(self.$t('general.http_error'));
                    })
                    .then(() => {
                        this.submitting = false;
                    });

                return false;
            }
        },
    }
};
</script>