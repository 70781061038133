<template>
    <section class="page help-page pb-5">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h1 class="page-title">{{ $t('notices.notice_title') }}</h1>
                    <p class="page-mesage">{{ $t('notices.notice_mesage')  }}</p>
                </div>
            </div>

            <!-- loader -->
            <loading-indicator v-if="!all_notices" />

            <div v-else class="row">
                <div class="col">
                    <!-- no public notices? -->
                    <span v-if="all_notices.length === 0" class="no-data-indicator">{{ $t('general.no_data') }}</span>

                    <template v-else>
                        <!-- a list of all notices -->
                        <ul class="notice-list">
                            <li v-for="entity in top_notices" :key="entity.id">
                                <div class="d-flex">
                                    <router-link class="flex-fill text-wrap" :to="'/notice/' + entity.id">
                                        <div v-if="entity.top === true" class="top-sign">{{ $t('bottom_nav.news') }}</div>
                                        <div v-html="entity.title"></div>
                                    </router-link>
                                    <div class="text-nowrap ps-3 top-time"> <span class="time-text">{{ new Date(entity.timeCreated).formatDate() + ' ' + formatTime(entity.timeCreated) }}</span></div>
                                </div>
                            </li>
                        </ul>
                        <ul class="notice-list">
                            <li v-for="entity in paged_notices" :key="entity.id">
                                <div class="d-flex">
                                    <router-link class="flex-fill text-wrap" :to="'/notice/' + entity.id">
                                        <div v-if="entity.top === true" class="top-sign">{{ $t('bottom_nav.news') }}</div>
                                        <div class="notice-title" v-html="entity.title"></div>
                                    </router-link>
                                    <div class="text-nowrap ps-3 normal-time"> <span class="time-text">{{ new Date(entity.timeCreated).formatDate() + ' ' + formatTime(entity.timeCreated) }}</span></div>
                                </div>
                            </li>
                        </ul>
                        <div class="btn-list">
                            <vue-pager :total="all_notices.length" :page_size="page_size" :page_index="page_index" @page-changed="onPageChanged" />
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </section>
</template>
<style scoped>
.page-title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 36px;
    color: #000922;
    line-height: 36px;
    margin-top: 100px;
    margin-bottom: 16px;
}
.page-mesage {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #7F8490;
    line-height: 13px;
    margin-bottom: 30px;
}
.notice-list:first-child {
    margin-bottom: 12px;
}
.notice-list li {
    height: 48px;
    line-height: 48px;
    margin: 0 30px;
    padding: 0;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #303F51;
}
.notice-list li:last-child {
    border: none;
}
.d-flex .notice-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.d-flex a {
    display: flex;
    justify-content: left;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.top-time {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #303F51;
}
.normal-time {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #838C97;
}
.top-sign {
    width: 36px;
    height: 16px;
    background: #1673F9;
    border-radius: 3px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 16px;
    margin-right: 10px;
    text-align: center;
}
</style>
<script type="text/javascript">
import VuePager from '../Components/VuePager.vue';

export default {
    components: { VuePager },

    data: function () {
        return {
            // An array of notices
            all_notices: null,

            // The current page index
            page_index: 0,

            // The hard-coded page size
            page_size: 13
        };
    },

    created() {
        this.readAllNotices();
    },

    watch: {
        '$i18n.locale': function (newVal, oldVal) {
            this.readAllNotices();
        }
    },

    computed: {
        paged_notices: function () {
            const input = this.all_notices;
            if (input && input.length > 0) {
                const minPageIndex = this.page_index * this.page_size;
                const maxPageIndex = (this.page_index + 1) * this.page_size;

                const output = input.filter((elm, index) => {
                    return index >= minPageIndex && index < maxPageIndex;
                });
                return output;
            }

            return [];
        },
        top_notices: function () {
            return this.all_notices.filter(notice => notice.top === true)
        }
    },

    methods: {
        readAllNotices: function () {
            const self = this;
            self.all_notices = null;

            $.callGetApi(self, '/api/v1/notices').then((json) => {
                if (json.errcode === 0 && json.data) {
                    self.all_notices = Object.freeze(json.data);
                }
            });
        },
        padZero(value) {
            return value < 10 ? '0' + value : value;
        },
        formatTime(timestamp) {
            const date = new Date(timestamp * 1000);
            return `${this.padZero(date.getHours())}:${this.padZero(date.getMinutes())}:${this.padZero(date.getSeconds())}`;
        },

        onPageChanged: function (pageIndex,num) {
            const isProvider = this.page_index + pageIndex >= 0
            const isNext = this.page_index + pageIndex < this.all_notices.length / this.page_size
            if (num) {
                this.page_index = pageIndex;
            } else if(isProvider && isNext){
                console.log(num,pageIndex)
                this.page_index += pageIndex;
            }
        }
    }
};
</script>