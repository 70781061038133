<template>
    <div v-if="filteredSymbols.length > 0">
        <section class="homepage-market d-none d-md-block">
            <div class="market-wrap">
                <dl>
                    <dt class="explain" :class="{'index-page':indexPage}">
                        <div class="explain-item">{{ $t('orders.label_symbol') }}</div>
                        <div class="explain-item">{{ $t('home.price') }}</div>
                        <div class="explain-item">{{ $t('home.24h_change') }}</div>
                        <div class="explain-item" v-if="!indexPage">{{ $t('home.24h_low') }}</div>
                        <div class="explain-item" v-if="!indexPage">{{ $t('home.24h_high') }}</div>
                        <div class="explain-item">{{ $t('home.symbol_action') }}</div>
                    </dt>
                    <div v-if="!indexPage">
                        <dd v-for="(symbol, index) in filteredSymbols" :key="index" class="item-info" @click="gotoTrade(symbol)">
                            <router-link :to="symbol.getRoutePath()">
                                <div class="text-uppercase d-flex align-items-center">
                                    <div class="pe-2"><token-icon :symbol="symbol" /></div>
                                    <div>{{ symbol.metadata.name }}</div>
                                </div>
                                <div class="d-flex" growing-ignore="true">${{ symbol.price }}</div>
                                <div class="d-flex" growing-ignore="true">
                                    <span :class="symbol.up ? 'rate up' : 'rate down'">
                                        {{ symbol.price_change_pct }}
                                    </span>
                                </div>
                                <div class="d-flex" growing-ignore="true">
                                    <span :class="symbol.up ? 'rate up' : 'rate down'">
                                        {{ symbol.day_low }}
                                    </span>
                                </div>

                                <div class="d-flex" growing-ignore="true">
                                    <span :class="symbol.up ? 'rate up' : 'rate down'">
                                        {{ symbol.day_high }}
                                    </span>
                                </div>
                                <div class="d-flex buy"><span>{{ $t('general.trade') }}</span></div>
                            </router-link>
                        </dd>
                    </div>
                    <div v-if="indexPage">
                        <dd v-for="(symbol, index) in filteredSymbols" :key="index" class="item-info index-list" @click="gotoTrade(symbol)">
                            <router-link :to="symbol.getRoutePath()">
                                <div class="text-uppercase d-flex align-items-center">
                                    <div class="pe-2"><token-icon :symbol="symbol" /></div>
                                    <div>{{ symbol.metadata.name }}</div>
                                </div>
                                <div class="d-flex" growing-ignore="true">${{ symbol.price }}</div>
                                <div class="d-flex" growing-ignore="true">
                                    <span :class="symbol.up ? 'rate up' : 'rate down'">
                                        {{ symbol.price_change_pct }}
                                    </span>
                                </div>
                                <div class="d-flex buy"><span>{{ $t('general.trade') }}</span></div>
                            </router-link>
                        </dd>
                    </div>
                </dl>
            </div>
        </section>
        <section v-if="!indexPage" class="homepage-market-sm d-lg-none d-md-none no-index-page">
            <dl>
                <dd class="title row">
                    <dl>{{ $t('orders.label_symbol') }}</dl>
                    <dl>{{ $t('home.price') }}</dl>
                    <em>{{ $t('home.24h_change') }}</em>
                </dd>
                <dd v-for="(symbol, index) in filteredSymbols" :key="index" @click="gotoTrade(symbol)" class="row">
                    <dl>
                        <dt class="text-uppercase d-flex align-items-center token-name">
                            <div class="pe-2"><token-icon :symbol="symbol" /></div>
                            <div class="item-name">{{ symbol.metadata.name }}</div>
                        </dt>
                    </dl>
                    <dl class="item-price">
                        <dt growing-ignore="true">$ {{ symbol.price }}</dt>
                    </dl>
                    <em :class="symbol.up ? 'bg-up' : 'bg-down'" growing-ignore="true">{{ symbol.price_change_pct }}</em>
                </dd>
            </dl>
        </section>
        <section v-if="indexPage" class="homepage-market-sm d-lg-none d-md-none md-index-page">
            <dl>
                <dd v-for="(symbol, index) in filteredSymbols" :key="index" @click="gotoTrade(symbol)" class="row">
                    <dl>
                        <dt class="text-uppercase d-flex align-items-center token-name">
                            <div class="pe-2"><token-icon :symbol="symbol" /></div>
                            <div class="item-info">
                                <div class="item-name">{{ symbol.metadata.name }}</div>
                                <dt growing-ignore="true" v-if="indexPage">{{ symbol.price }}</dt>
                            </div>
                        </dt>
                    </dl>
                    <dl class="item-price">
                        <dt growing-ignore="true" v-if="!indexPage">$ {{ symbol.price }}</dt>
                        <dd v-if="!indexPage" growing-ignore="true">${{ symbol.price_change }}</dd>
                    </dl>
                    <em :class="symbol.up ? 'bg-up' : 'bg-down'" growing-ignore="true">{{ symbol.price_change_pct }}</em>
                </dd>
            </dl>
        </section>
    </div>
</template>
<style scoped>
.index-page {
    background-color: #fff;
}
.index-page .explain-item {
    width: 25%;
    text-align: right;
}
.index-page .explain-item:first-child {
    text-align: left;
}
.index-list .d-flex {
    width: 25%;
}
.index-list .d-flex:first-child {
    justify-content: left;
}
.explain {
    justify-content: space-between;
    padding: 0 20px;
}
.explain div {
    width: auto;
    padding: 0;
}
.item-info a {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
}
.item-info a div {
    width: auto;
}
.explain div:nth-child(1),
.explain div:nth-child(2),
.explain div:nth-child(3),
.explain div:nth-child(4),
.explain div:nth-child(5) {
    width: 10%;
    text-align: left;
}
.explain div:nth-child(6) {
    width: 10%;
    text-align: right;
}
.d-flex:nth-child(1),
.d-flex:nth-child(2),
.d-flex:nth-child(3),
.d-flex:nth-child(4),
.d-flex:nth-child(5) {
    width: 10%;
    text-align: left;
}
.buy {
    display: flex;
    justify-content: end;
    width: 10%;
}
.buy span {
    display: block;
    width: 60px;
    height: 20px;
    border-radius: 5px;
    line-height: 0;
    font-size: 12px;
}
.pe-2 i .svg-icon{
    justify-content: space-between;
}
.item-info .align-items-center {
    height: 100%;
    padding: 0;
    width: 10%;
}
.item-info .align-items-center .pe-2 {
    width: 30px;
    height: 30px;
}
@media (max-width: 768px) {
    .token-icon {
        width: 22px;
        height: 22px;
        line-height: 22px;
    }
    .item-name {
        font-size: 12px;
    }
    .item-price {
        display: flex;
        align-items: center;
    }
    .row {
        padding: 0;
        align-items: center;
    }
    .row dl {
        padding: 0;
        height: 100%;
    }
    .row .token-name {
        height: 100%;
        align-items: center;

    }
    .md-index-page .row {
        padding: 20px;
        box-sizing: border-box;
        width: 100%;
        height: 88px;
    }
    .md-index-page .item-name {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #79818F;
        line-height: 20px;
    }
    .md-index-page dt {
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #18191C;
        line-height: 24px;
    }
    .md-index-page .row .bg-up {
        background: none;
        color: var(--up);
    }
    .md-index-page .row .bg-down {
        background: none;
        color: var(--down);
    }
    .no-index-page .row {
        height: 50px;
        align-items: center;
    }
    .no-index-page .row .bg-up {
        background: none;
        color: var(--up);
    }
    .no-index-page .row .bg-down {
        background: none;
        color: var(--down);
    }
    .no-index-page .title {
        height: 40px;
        align-items: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #848894;
    }
    .pe-2 {
        height: 100%;
        padding-right: 20px;
    }
    .item-info {
        height: 100%;
    }
}
</style>
<script>
import { getAppContext } from 'utilities/helper';
import TokenIcon from '../../Components/TokenIcon.vue';

export default {
    components: { TokenIcon },
    props: ['title', 'symbols', 'typeFilter', 'searchTerm' , 'indexPage'],

    computed: {
        filteredSymbols: function () {
            if (!this.symbols) {
                return [];
            }

            // Determines if test mode is enabled or not for the current user.
            const context = getAppContext();
            let testMode = false;
            if (context && context.profile) {
                testMode = context.profile.testEnabled === true;
            }

            const self = this;
            const term =
                this.searchTerm && typeof this.searchTerm === 'string'
                    ? this.searchTerm.trim().toUpperCase() // Always use upper case
                    : '';
            if (term && term.length > 0) {
                return this.symbols.filter((s) => {
                    return s.metadata.type === self.typeFilter && (testMode || !s.metadata.testOnly) && s.metadata.name.indexOf(term) >= 0;
                }).slice(0, this.indexPage ? 5 : undefined);
            } else {
                // Return symbols for the specified type.

                return this.symbols.filter((s) => {
                    return s.metadata.type === self.typeFilter && (testMode || !s.metadata.testOnly);
                }).slice(0, this.indexPage ? 5 : undefined);
            }
        }
    },

    methods: {
        gotoTrade: function (sym) {
            this.$emit('symbol-selected', sym);
        },

        getVisibleSids: function () {
            const output = [];
            $(this.filteredSymbols).each((index, sym) => {
                output.push(sym.metadata.id);
            });

            return output;
        }
    }
};
</script>