<template>
    <section class="page startup-page support-iframe">
        <!-- page title -->
        <div class="page-top">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="title-box">
                            <h1>{{ $t('general.startup') }}</h1>
                            <div class="title-des media-display">{{ $t('home.guide_verify_identity_long') }}</div>
                            <div class="step-img-box media-display">
                                <div class="img-box">
                                    <img class="step-img" src="../../assets/images/v4.2/startupFirst.png" alt="">
                                    <div class="step-des">{{ $t('general.no_data') }}</div>
                                </div>
                                <div class="line"></div>
                                <div class="img-box">
                                    <img class="step-img" src="../../assets/images/v4.2/startupSecond.png" alt="">
                                    <div class="step-des">{{ $t('general.no_data') }}</div>
                                </div>
                                <div class="line"></div>
                                <div class="img-box">
                                    <img class="step-img" src="../../assets/images/v4.2/startupThird.png" alt="">
                                    <div class="step-des">{{ $t('general.no_data') }}</div>
                                </div>
                            </div>
                        </div>
                        <img src="../../assets/images/v4.2/startupLogo.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <section class="pro_layout container">

            <ul class="page-top-nav mb-3">
                <li :class="{ active: !ongoing_tokens() }">
                    <router-link to="/startup?type=forecast">{{ $t('startup.title_forecast') }}</router-link>
                </li>
                <li :class="{ active: ongoing_tokens() }">
                    <router-link to="/startup?type=ongoing">{{ $t('startup.title_ongoing') }}</router-link>
                </li>
                <li>
                    <router-link to="/startup/orders">{{ $t('startup.title_orders') }}</router-link>
                </li>
            </ul>

            <!-- Display a list of tokens -->
            <token-list :ongoing_tokens="ongoing_tokens()" />
        </section>

        <ext-resource-i-frame />
    </section>
</template>
<style scoped>
.page-top {
    background: linear-gradient(to bottom,#EFF5FF,#E4EFFF);
}
.page-top .container {
    margin: 43px auto 21px;
}
.page-top .col {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.col .title-box {
    flex: 1;
}
.line {
    margin: 0;
    width: 27px;
    background: #7F8490;
}
.media-display {
    visibility: hidden;
}
.page-top .col img {
    width: 177px;
    height: 150px;
}
.title-box .step-img-box {
    display: flex;
    width: 100%;
    align-items: center;
}
.step-img-box .line {
    margin-top: -13px;
}
.title-box .step-img-box .step-img {
    width: 24px;
    height: 24px;
}
.img-box {
    text-align: center;
}
.img-box .step-des {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 10px;
    color: #7F8490;
    line-height: 14px;
    word-break: keep-all;
}
.page-top .col h1 {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 36px;
    color: #000922;
    line-height: 50px;
}
.page-top-nav {
    margin: 40px 0 30px;
    border: none;
}
.page-top-nav li {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 20px;
    color: #969699;
    line-height: 30px;
}
.page-top-nav li.active {
    border: none;
}
.page-top-nav li.active a {
    font-weight: 600;
    color: #242428;
}
@media (max-width: 768px) {
    .page {
        background: #FAFAFA;
    }
    .step-img {
        height: 24px;
    }
    .page-top,.pro_layout {
        background-color: #fff;
    }
    .title-box h1 {
        margin: 0;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 24px;
        color: #000922;
        line-height: 24px;
    }
    .title-des {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #1673F9;
        line-height: 16px;
        margin: 8px 0 22px;
    }
    .media-display {
        visibility: visible;
    }
    .page-top-nav {
        margin: 0 0 23px;
    }
    .page-top-nav .active {
        border-bottom: 2px solid #1673F9;
    }
    .page-top .col img {
        transform: scale(.93);
    }
}
</style>


<script>
import ExtResourceIFrame from '../Components/ExtResourceIFrame.vue';
import TokenList from './Components/TokenList.vue';

export default {
    components: { ExtResourceIFrame, TokenList },

    data() {
        return {
            // Display on-going  or forecast tokens?
        };
    },
    methods: {
        ongoing_tokens() {
            const queryType = this.$route.query.type;
            
            if (queryType === 'ongoing') {
                return true;
            } else {
                return false;
            }
        }
    }
};
</script>