<template>
    <section class="page login-page layout-login">
        <div class="login-page-right login-container">
            <div class="login-page-box">
                <div class="img">
                    <div class="title">
                        <div class="title_item title_text">{{ $t('user.label_id_waiting') }}</div>
                        <div class="title_item title_num">{{ $t('user.vip1') }}</div>
                        <div class="title_item title_text">{{ $t('user.label_id_verification') }}</div>
                    </div>
                    <div class="subtitle">
                        <div class="subtitle_item subtitle_text">{{ $t('register.label_confirm_password') }}</div>
                        <div class="subtitle_item subtitle_num">{{ $t('user.vip1') }}</div>
                        <div class="subtitle_item subtitle_text">{{ $t('register.label_confirm_password') }}</div>
                    </div>
                    <img class="login-img" src="../../assets/images/v4.2/loginBack.png" alt="" />
                </div>
                <div class="login-box">
                    <div class="login container ">
                        <div class="login-inner" id="login-page">
                            <div class="tab-row-box d-none d-lg-block">
                                <div class="tab-row">
                                    <router-link to='/user/login' class="tab-row-login" :class="{highlight:tabStatus=='login'}">{{ $t('login.login_button') }}</router-link>
                                    <router-link  to="/user/create" class="tab-row-register" :class="{highlight:tabStatus=='create'}">{{ $t('register.title') }}</router-link>
                                </div>
                            </div>
                            <h1 class="d-lg-none md-title">{{ $t('register.label_register') }}</h1>
                            <loading-indicator v-if="!config" />
                            <template v-else>
                                <create-account-component v-if="config.signupEnabled === true" :config="config" :regions="regions" :referral="id" />
                                <div class="container" v-else>
                                    <div class="row">
                                        <div class="col">
                                            <div class="alert alert-info mt-5">
                                                <h1>{{ $t('login.system_update') }}</h1>
                                                <p>{{ $t('login.system_update_des') }}</p>
                                                <hr />
                                                <router-link to="/user/login">{{ $t('login.title') }}</router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<style scoped>
.login-left-right {
    margin: auto;
    width: 100%;
}
.login-left-right .login-container {
    background: linear-gradient( 2deg, #FFFFFF 0%, #CAE5FE 100%);
}
.img img{
    width: 31.375rem;
}
.login-container .login-box {
    background: none;
}
.login-page-box {
    background: linear-gradient( 2deg, #FFFFFF 0%, #CAE5FE 100%);
    display: flex;
    justify-content: center;
    padding: 8.75rem 7.5rem 7.5rem 7.5rem;
}
.login-page-box .img {
    /* margin-right: 8.75rem; */
    margin-left: 0;
}
.img img {
    width: auto;
    height: 20.9375rem;
    margin-left: 0;
}
.login-page-box .login-box {
    background-color: transparent;
    width: auto;
    padding: 0;
}
.login-box .container {
    width: 35rem;
    background: #FFFFFF;
    box-shadow: 0px 1px 15px 0px rgba(0,0,0,0.05);
    border-radius: 4px;
    flex-grow: 0;
    padding: 2.5rem 3.75rem 3.75rem 3.75rem;
}
#login-page .tab-row {
    height: 2.5rem;
    line-height: 2rem;
    border: 1px solid #1673F9;
    width: 100%;
    display: flex;
    text-align: left;
    padding: .25rem;
    margin-bottom: 2.5rem;
}
#login-page .tab-row .tab-row-login,
.tab-row-register {
    width: 50%;
    text-align: center;
    color: #2B66cc;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    font-family: PingFangSC, PingFangSC;
    cursor: pointer;
}
#login-page .highlight {
    background-color: #1673F9;
    color: #FFFFFF;
}
.img .subtitle {
    margin-bottom: 2.875rem;
    width: 16.5rem;
    height: 1.4375rem;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.4375rem;
    text-align: left;
    font-style: normal;
    letter-spacing: .5px;
    white-space: nowrap;
    padding-left: 7.6875rem;
}
.subtitle_item {
    display: inline;
    color: #08337C;
}
.subtitle_num {
    color: #FF811A;
}
.img .title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 24px;
    line-height: 2.25rem;
    text-align: left;
    font-style: normal;
    letter-spacing: 1px;
    white-space: nowrap;
    margin: auto;
    padding-left: 7.6875rem;
    margin-bottom: .625rem;
}
.title .title_item {
    display: inline;
}
.title .title_text {
    color: #0C357A;
}
.title .title_num {
    color: #F57B17;
}
.login-bottom {
    width: 100%;
    margin-bottom: 80px;
}
.login-bt-title .welcome {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 28px;
    color: #000000;
    line-height: 40px;
    margin-bottom: 12px;
    text-align: center;
}
.login-bt-title .welcome-des {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 20px;
    color: #7F8490;
    line-height: 22px;
    text-align: center;
}
.login-flex-box {
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.login-flex-item {
    text-align: center;
}
.img-title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 14px;
    color: #000922;
    line-height: 20px;
    margin-top: 14px;
    margin-bottom: 14px;
}
.img-des {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #7F8490;
    line-height: 20px;
}

@media (max-width: 768px) {
    .login-container {
        background: var(--bg-primary);
    }
    .login-page-box {
        width: 100%;
        height: 100%;
        padding: 0;
    }
    .login-container .img {
        display: none;
    }

    .login-container .login-box {
        width: inherit;
    }

    .login-page .login h1 {
        color: #000;
    }

    .login-box .container {
        width: 100%;
        height: 100%;
        padding: 12px;
    }
}
</style>
<script>
import { getConfigAsync } from 'utilities/helper';
import CreateAccountComponent from './Components/CreateAccount.vue';

export default {
    components: { CreateAccountComponent },
    props: ['id'],

    data() {
        return {
            config: null,

            // All supported regions
            regions: null,
            tabStatus:'create'
        };
    },

    created() {
        this.initAsync();
    },

    watch: {
        '$i18n.locale': function () {
            this.reloadRegionsAsync();
        }
    },

    methods: {
        initAsync: async function () {
            const self = this;

            let config = await getConfigAsync();
            let resp = await $.callGetApi(self, '/api/v1/config/regions');
            if (resp) {
                self.regions = resp.data || [];
                self.config = config;
            }
        },

        reloadRegionsAsync: async function () {
            // Read a list of supported regions.
            const resp = await $.callGetApi(self, '/api/v1/config/regions');
            if (resp && resp.data) {
                this.regions = resp.data;
            }
        }
    },
    beforeRouteEnter(to,from,next) {
        next(vm => {
            vm.tabStatus = to.path.split('/')[2]
        })
    }
};
</script>