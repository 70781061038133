<template>
    <section class="page home-page">
        <section class="pro_layout">
            <loading-indicator v-if="!symbols" />
            <div v-else>
                <!-- top notice -->
                <div class="page-part" v-if="context.homeConfig && context.homeConfig.promptedNotices && context.homeConfig.promptedNotices.length">
                    <home-notices :promptedNotices="context.homeConfig.promptedNotices" />
                </div>
                <home-quotes ref="quotes" :symbols="symbols" />

                <partner-links v-if="context && context.homeConfig && context.homeConfig.spl === true" />
            </div>
        </section>
    </section>
</template>

<style scoped>
.sm-navs {
    /* background: #000;
    color: #fff; */
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
}

/* .sm-navs .row > .col {
    border-right: 1px solid 1px solid var(--divider-secondary);
}
.sm-navs .row > .col:last-child {
    border-right: none;
} */

.sm-navs a {
    color: var(--link-color);
}

.sm-navs svg.svg-icon {
    display: block;
    height: 1.5rem;
    width: auto;
    margin: auto;
    margin-bottom: 0.5rem;
    fill: var(--primary-base);
    /* stroke: var(--primary-base); */
}

.svg-box {
    height: 1.5rem;
    position: relative;
    margin-bottom: 0.5rem;
}

.sm-navs svg.svg-icon.notice-svg {
    height: 2.8rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
</style>

<script type="text/javascript">
// import PostsComponent from './Components/Posts.vue';
import SymbolList from './Components/SymbolList.vue';
import TopBanners from './Components/TopBanners-4.2.vue';
import HomeNotices from './Components/HomeNotices.vue';
import HomePosts from './Components/HomePosts.vue';
// import Features from './Components/Features.vue';
import PromptedSymbols from './Components/PromptedSymbols.vue';
import Introduction from './Components/Introduction-4.2.vue';
import HomeQuotes from './Components/HomeQuotes.vue';
import HomeCarousel from './Components/HomeCarousel.vue';
import InlineSvgIcons from '../Components/_InlineSvgIcons.vue';
import PartnerLinks from './Components/_PartnerLinks-4.2.vue';

// import '@/assets/svg/icon-rocket.svg';
// import '@/assets/svg/icon-team.svg';
import '@/assets/svg/icon-login.svg';
import '@/assets/svg/icon-wallet.svg'
import '@/assets/svg/icon-assets.svg';;
// import '@/assets/svg/icon-trust.svg';
// import '@/assets/svg/icon-dollar-coin.svg';
import '@/assets/svg/icon-invite.svg';
import '@/assets/svg/icon-notice.svg';


import '@/assets/svg/v4.2/v4.2-login.svg';
import '@/assets/svg/v4.2/v4.2-wallet.svg';
import '@/assets/svg/v4.2/v4.2-assets.svg';
import '@/assets/svg/v4.2/v4.2-notice.svg';

import { SymbolInfo } from 'utilities/SymbolInfo.js';
import { getSymbolManagerAsync, getAppContext } from 'utilities/helper';

let g_symbolMap = {};
let g_destoryed = false;
let g_quoteTimerId = 0;

export default {
    components: { TopBanners, SymbolList, HomeNotices, HomePosts, Introduction, PromptedSymbols, HomeQuotes, HomeCarousel, InlineSvgIcons, PartnerLinks },

    data() {
        return {
            // All symbols.
            symbols: null,

            // App context
            context: null

            // UPDATE:
            // Sorted symbols is deprected for now.
            //
            // sort_market: 2,
            // sorted_symbols: null,
            // sort_asc: false
        };
    },

    computed: {
        // return a list of prompted symbols
        promptedSymbols: function () {
            let arr = this.symbols.filter(function (s) {
                return s.metadata.prompted && !s.metadata.testOnly;
            });
            return arr;
        }
    },

    created() {
        g_destoryed = false;
        this.context = getAppContext();

        // clear all existing symbols
        g_symbolMap = {};
        this.initPageAsync();
    },

    beforeDestroy() {
        g_destoryed = true;

        // clear all timers
        clearTimeout(g_quoteTimerId);
    },

    methods: {
        initPageAsync: async function () {
            // Read symbols.
            const mgr = await getSymbolManagerAsync();
            const sidMap = {};
            if (mgr) {
                const self = this;

                let arr = [];
                $(mgr.getAllSymbols()).each((index, item) => {
                    let sym = new SymbolInfo(item);
                    arr.push(sym);

                    sidMap[sym.metadata.id] = sym;
                });

                g_symbolMap = Object.freeze(sidMap);
                self.symbols = arr;

                Vue.nextTick(() => {
                    // start to subscribe realtime quote data
                    self.syncQuotes();
                });
            }

            // UPDATE:
            // Sorted symbols is deprected for now.
            //
            // this.sortSymbolsAsync(false);
        },

        gotoTradePage: function (sym) {
            let route = sym.getRoutePath();
            this.$router.push({ path: route });
        },

        syncQuotes: function () {
            const self = this;

            // clear existing timer if any
            clearTimeout(g_quoteTimerId);

            const sids = [];
            $(this.promptedSymbols).each((index, sym) => {
                sids.push(sym.metadata.id);
            });

            try {
                const temp = this.$refs.quotes.getVisibleSids();
                if (Array.isArray(temp)) {
                    for (let i = 0; i < temp.length; i++) {
                        sids.push(temp[i]);
                    }
                }
            } catch (err) {
                console.error(err);
            }

            // UPDATE:
            // Sorted symbols is deprected for now.
            //
            // if (this.sorted_symbols) {
            //     $(this.sorted_symbols).each((index, sym) => {
            //         sids.push(sym.metadata.id);
            //     });
            // }

            if (sids.length > 0) {
                // Read quotes from server
                self.$http
                    .get('/api/v1/quotation/latest?symbols=' + sids.join(','))
                    .then((json) => {
                        const quotes = json.data;
                        if (quotes.length > 0) {
                            for (let i = 0; i < quotes.length; i++) {
                                const quote = quotes[i];
                                const sym = g_symbolMap[quote.id];
                                if (sym) {
                                    sym.update(quote);
                                }
                            }
                        }
                    })
                    .then(() => {
                        if (g_destoryed === false) {
                            // always restart timer
                            let delay = 4000 + Math.round(Math.random() * 3000);
                            g_quoteTimerId = setTimeout(() => {
                                self.syncQuotes();
                            }, delay);
                        }
                    });
            }
        }

        // UPDATE:
        // Sorted symbols is deprected for now.
        //
        // changeMarket: function (market) {
        //     this.sort_market = market;
        //     this.sortSymbolsAsync();
        // }

        // UPDATE:
        // Sorted symbols is deprected for now.
        //
        // sortSymbolsAsync: async function (asc) {
        //     this.sort_asc = asc;
        //     this.sorted_symbols = null;

        //     const resp = await $.callGetApi(this, '/api/v1/quotation/topsymbols?type=' + encodeURIComponent(this.sort_market) + '&asc=' + (asc ? 'true' : 'false'));
        //     const output = [];
        //     if (Array.isArray(resp)) {
        //         $(resp).each((index, quote) => {
        //             var sym = g_symbolMap[quote.id];
        //             if (sym) {
        //                 sym.update(quote);
        //                 output.push(sym);
        //             }
        //         });
        //     }
        //     this.sorted_symbols = output;
        // }
    }
};
</script>
