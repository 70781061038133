<template>
    <section>
        <div class="table compact startup-table">
            <div v-if="tokens" class="list-box media-none">
                <div class="list-item" v-for="token in tokens" :key="token.id">
                    <div class="table-top">
                        <div class="table-left">
                            <div class="d-flex align-items-center">
                                <div v-if="token.svgIcon" class="pe-2">
                                    <startup-icon :token="token" />
                                </div>
                                <div class="item-title">{{ token.token }}</div>
                            </div>
                            <div class="data-info">
                                <div class="data-num-box">
                                    <div class="data-num">{{ token.offerPrice.toFixed(token.pricePrecision) + ' USDT' }}</div>
                                    <div class="num-des">{{ $t('futures.depth_price') }}</div>
                                </div>
                                <div class="data-num-box">
                                    <div class="data-num">{{ token.targetAmount }}</div>
                                    <div class="num-des">{{ $t('exchange.orders_executed_long') }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="table-right">
                            <div v-show="ongoing_tokens" class="in-progress">{{ $t('startup.title_ongoing') }}</div>
                            <div class="end-time">{{ $t('startup.orders_end_time') + ': ' + remainingTime(token.endTime) }}</div>
                            <div :class="ongoing_tokens?'':'disabled'">
                                <router-link :to="ongoing_tokens?'/startup/' + token.token.toLowerCase():''" class="btn btn-primary">{{ ongoing_tokens?$t('startup.label_subscribe_sm'):$t('startup.title_forecast') }}</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="table-bottom">
                        <div :class="ongoing_tokens ? 'd-none d-md-table-cell' : 'd-md-table-cell'">{{ $t('startup.label_max_sub')+ ':' }}</div>
                        <div :class="ongoing_tokens ? 'd-none d-md-table-cell' : 'd-md-table-cell'">{{ token.minSubscriptionAmount + ' USDT' }}</div>
                        <div :class="ongoing_tokens ? 'd-none d-md-table-cell' : 'd-md-table-cell'">{{ '- ' + token.maxSubscriptionAmount + ' USDT' }}</div>
                    </div>
                </div>
            </div>
            <div v-if="tokens" class="list-box media-display">
                <div class="list-item" v-for="token in tokens" :key="token.id">
                    <div class="table-top">
                        <div class="d-flex align-items-center">
                            <div v-if="token.svgIcon" class="pe-2">
                                <startup-icon :token="token" />
                            </div>
                            <div class="item-title-box">
                                <div class="item-title">{{ token.token }}</div>
                                <div class="end-time">{{ $t('startup.orders_end_time') + ': ' + remainingTime(token.endTime) }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="table-bottom">
                        <div class="max-box">
                            <div class="max-title">{{ $t('startup.label_max_sub') }}</div>
                            <div class="d-md-table-cell">{{ token.maxSubscriptionAmount }}</div>
                        </div>
                        <div class="min-box">
                            <div class="min-title">{{ $t('startup.label_min_sub') }}</div>
                            <div class="d-md-table-cell">{{ token.minSubscriptionAmount }}</div>
                        </div>
                        <div :class="ongoing_tokens?'':'disabled'">
                            <router-link :to="ongoing_tokens?'/startup/' + token.token.toLowerCase():''" class="btn btn-primary">{{ ongoing_tokens?$t('startup.label_subscribe_sm'):$t('startup.title_forecast') }}</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <loading-indicator v-if="!tokens" />
        <template v-else>
            <div v-if="tokens.length === 0" class="no-data-indicator m-5">{{ $t('orders.no_orders') }}</div>
        </template>
    </section>
</template>

<style scoped>
.media-display {
    display: none;
}
.media-none {
    display: block;
}
.startup-table {
    padding: 0 20px;
    border-radius: 8px;
    border: 1px solid #EDEEEF;
    --bs-table-bg: none;
    box-sizing: border-box;
    border-collapse: inherit;
}
.startup-table .list-box {
    width: 100%;
    height: 100%;
}
.list-item {
    border-bottom: 1px solid #EDEEEF;
    padding: 20px 0;
}
.list-item .table-bottom {
    margin-top: 20px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #969699;
    line-height: 20px;
}
.table-bottom .d-md-table-cell:first-child {
    margin-right: 12px;
}
.table-top {
    display: flex;
    justify-content: space-between;
}
.item-title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 20px;
    color: #333333;
    line-height: 28px;
}
.table-left {
    flex: 1;
}
.data-info {
    margin-top: 73px;
    display: flex;
}
.data-num-box {
    width: 30%;
    text-align: left;
}
.data-num-box .data-num {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #000922;
    line-height: 20px;
    margin-bottom: 10px;
}
.data-num-box .num-des {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #969699;
    line-height: 20px;
}
.table-right {
    position: relative;
    text-align: right;
}
.table-right .disabled a{
    background-color:#EFEFEF;
    border: none;
}
.table-right a {
    padding: 8px 68px;
}
.in-progress {
    background: rgba(22,115,249,0.1);
    border-radius: 4px;
    width: 82px;
    height: 26px;
    line-height: 28px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 12px;
    color: #1673F9;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
}
.end-time {
    margin-top: 70px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #D75F5F;
    line-height: 20px;
    margin-right: 1em;
    margin-bottom: 20px;
}
@media (max-width: 768px) {
    .media-display {
        display: block;
    }
    .media-none {
        display: none;
    }
    .list-item {
        padding: 0;
    }
    .table-top {
        border-bottom: 1px solid rgba(237,238,239,0.8);
    }
    .item-title {
        font-size: 14px;
        line-height: 20px;
    }
    .end-time {
        margin: 0;
        font-size: 10px;
        color: #969699;
        line-height: 14px;
        margin-top: 2px;
    }
    .table-bottom {
        display: flex;
        justify-content: space-between;
        align-items: end;
        margin: 0;
    }
    .max-title,.min-title {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #969699;
        line-height: 18px;
        margin-bottom: 16px;
    }
    .max-box,.min-box {
        text-align: left;
    }
    .d-md-table-cell {
        font-family: Helvetica, Helvetica;
        font-weight: bold;
        font-size: 16px;
        color: #1673F9;
        line-height: 18px;
    }
    .disabled a{
        background-color:#EFEFEF;
        border: none;
    }
    .table-bottom a {
        padding: 5px 14px;
        font-size: 12px;
        border: none;
    }
}
</style>

<script>
import StartupIcon from '../../Components/_StartupIcon.vue';
import TokenProgress from './_Progress.vue';

export default {
    components: { StartupIcon, TokenProgress },
    props: ['ongoing_tokens'],

    data() {
        return {
            tokens: null
        };
    },

    watch: {
        /**
         * Update token list when the ongoing_tokens property is updated.
         */
        ongoing_tokens: function () {
            this.tokens = null;
            this.getTokensAsync();
        }
    },

    mounted() {
        this.getTokensAsync();
    },

    methods: {
        getTokensAsync: async function () {
            const self = this;
            const json = await $.callGetApi(self, '/api/v1/startup/' + (this.ongoing_tokens ? 'ongoing' : 'forecast'));
            if (json && json.errcode === 0) {
                self.tokens = json.data;

                Vue.nextTick(() => {
                    $.scanPopup();
                });
            }
        },
        remainingTime(time) {
            let currentTime = new Date().getTime();

            let remainingTime = time - currentTime;

            let days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
            let hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

            return `${days}天${hours.toString().length<2 ? '0'+ hours:hours}:${minutes.toString().length<2 ? '0'+ minutes:minutes}:${seconds.toString().length<2 ? '0'+ seconds:seconds}`
        }
    }
};
</script>