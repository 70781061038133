<template>
    <nav aria-label="navigation" class="mt-3" v-if="pages.length > 1">
        <ul class="pagination">
            <li class="page-item page-to">
                <a class="page-link" href="javascript:;" aria-label="Previous" @click="onPage($event, -1)">&lt;</a>
            </li>
            <li class="page-item page-size">
                <a  class="page-link" href="javascript:;" @click="onPage($event, page, 1)">{{ page_index + 1 }}</a>
            </li>
            <li class="page-item page-to">
                <a class="page-link" href="javascript:;" aria-label="Next" @click="onPage($event, 1)">&gt;</a>
            </li>
        </ul>
    </nav>
</template>

<style scoped>
.page-link {
    color: #c99400;
    border-color: #f6f6f6;
}
.page-to {
    width: 32px;
    height: 32px;
    border-radius: 4px;
}
.page-to a {
    color: #7F8490;
    background: #F1F2F5;
}
.pagination {
    height: 32px;
}
.page-size {
    width: 54px;
    height: 32px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #E1E2E6;
    margin: 0 6px;
}
.page-size a {
    font-family: AppleSystemUIFont;
    font-size: 14px;
    color: #000922;
    line-height: 32px;
    height: 100%;
    padding: 0;
    text-align: center;
    border: none;
    margin-left: 0;
}
</style>

<script>
export default {
    props: ['total', 'page_size', 'page_index'],

    data() {
        return {
            pages: [],
            current_page: 0
        };
    },

    mounted() {
        this.resetPages();
    },

    watch: {
        /**
         * reset pages when either 'total' or 'page_size' gets changed.
         */
        total: function () {
            this.resetPages();
        },
        page_size: function () {
            this.resetPages();
        },
        page_index: function () {
            this.current_page = this.page_index * 1;
        }
    },

    methods: {
        resetPages: function () {
            let pageSize = this.page_size * 1;
            if (isNaN(pageSize)) {
                pageSize = 2;
            }

            let total = this.total * 1;
            if (isNaN(total)) {
                total = 0;
            }

            let maxPage = Math.ceil(total / pageSize);
            let output = [];
            for (let i = 0; i < maxPage; i++) {
                output.push(i);
            }

            this.current_page = 0;
            this.pages = Object.freeze(output);
        },

        onPage: function (event, page,num) {
            event.target.blur();

            console.log(`## Change page index to ${page}`);
            this.$emit('page-changed', page,num);
        }
    }
};
</script>