<template>
    <div class="mod-body flex-column">
        <div class="table-panel-tabs" id="trade-pannel-tabs">
            <button class="btn color-up" :class="{ active: buyUp === true }" @click="buyUp = true">{{ $t('futures.label_buy_up') }}</button>
            <button class="btn color-down" :class="{ active: buyUp === false }" @click="buyUp = false">{{ $t('futures.label_buy_down') }}</button>
        </div>
        <form action="javascript:void(0);" method="post" class="order-options-form" @submit="doConfirmOrder">
            <div class="single-panel limit shadow-sm">
                <input type="hidden" name="SymbolId" :value="symbol.metadata.id" />
                <input type="hidden" name="buyUp" :value="buyUp" />

                <div growing-ignore="true" class="row text-content">
                    <div class="col-auto">{{ $t('futures.label_available_margin') }}</div>
                    <div class="col-auto" v-if="isNaN(availableMargin)">--</div>
                    <div class="col-auto" v-else>
                        {{ Math.max(0, availableMargin).toFixed(4) }} USDT
                        <span v-if="!isNaN(maxHands)"> / {{ maxHands }} {{ $t('futures.label_unit') }} </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="d-flex flex-row text-label">
                            <div v-for="(val, index) in futuresConfig.supportedLeverages" :key="index" class="inline-radio flex-grow-1">
                                <input v-model="leverage" :id="'order-leverage-' + index" type="radio" name="leverage" :value="val" />
                                <label :for="'order-leverage-' + index">{{ val }}X</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row has-input">
                    <div class="col-auto text-label">
                        <span>{{ $t('futures.label_market_price') }}</span>
                    </div>
                    <label growing-ignore="true" class="col">
                        <input type="text" :value="symbol.price" readonly class="text-muted" />
                    </label>
                </div>
                <div class="row has-input">
                    <div class="col-auto text-label">{{ $t('futures.label_amount') }} ({{ $t('futures.label_unit') }})</div>
                    <label growing-ignore="true" class="col position-relative">
                        <input
                            type="number"
                            v-model="numHands"
                            name="num_hands"
                            maxlength="14"
                            autocomplete="off"
                            data-val="true"
                            data-val-number="*"
                            data-val-required="*"
                            data-val-range="*"
                            data-val-range-min="1"
                        />
                        <div class="action-links">
                            <a href="javascript:" class="action-link" @click="plusHands"><i class="fa fa-plus"></i></a>
                            |
                            <a href="javascript:" class="action-link" @click="minusHands"><i class="fa fa-minus"></i></a>
                        </div>
                    </label>
                </div>
                <div class="row total text-nowrap" growing-ignore="true">
                    <div class="col text-label">
                        {{ $t('futures.label_margin') }}
                        <span class="total-value">{{ requiredMargin }} USDT</span>
                    </div>
                    <div class="col-auto text-label">1 {{ $t('futures.label_unit') }} = {{ futuresConfig.orderUnit }} USDT</div>
                </div>
                <div class="row">
                    <div class="col">
                        <button v-if="buyUp" class="btn-submit bg-buy">{{ $t('futures.label_buy_up_long') }}</button>
                        <button v-else class="btn-submit bg-sell">{{ $t('futures.label_buy_down_long') }}</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>
<style scoped>
.single-panel {
    background: none;
}
.mod-body #trade-pannel-tabs .btn {
    background: #1D2027;
    color: #6A6E79;
}
.mod-body #trade-pannel-tabs .active {
    background-color: #15181C;
    color: #FFFFFF;
    border-bottom: 2px solid var(--primary-base);
}
.trade-layout .inline-radio label {
    background-color: #1D2027;
    color: #6A6E79;
}
.trade-layout .inline-radio input[type=radio]:checked + label {
    background-color: var(--primary-base);
}
</style>
<script>
export default {
    props: ['symbol', 'futuresConfig'],

    data() {
        return {
            buyUp: true,

            // The amount of available margin
            availableMargin: NaN,

            leverage: NaN,
            maxHands: NaN,

            // The number of hands to purchase.
            numHands: ''
        };
    },

    created() {
        this.leverage = this.futuresConfig.defaultLeverage;
    },

    computed: {
        requiredMargin: function () {
            const leverage = this.leverage * 1;
            if (Number.isInteger(leverage) && leverage > 0) {
                const margin = (this.numHands * this.futuresConfig.orderUnit) / leverage;
                return !isNaN(margin) && margin >= 0 ? Math.round(margin) : '--';
            } else {
                return '--';
            }
        }
    },

    watch: {
        leverage: function () {
            this._updateMaxHands();
        },

        availableMargin: function () {
            this._updateMaxHands();
        },

        numHands: function () {
            // Make sure to use integers
            if (this.numHands !== '') {
                const val = this.numHands * 1;
                const target = isNaN(val) || val < 1 ? 1 : Math.round(val);
                if (val !== target) {
                    this.numHands = target;
                }
            }
        }
    },

    mounted() {
        $.resetValidators();
    },

    methods: {
        ///////////////////////////////////////////////////////////////////////////////////////
        /**
         * Update the amount of available margin.
         */
        setAvailableMargin: function (margin) {
            this.availableMargin = Math.max(0, margin);
        },

        setOrderType: function (up) {
            this.buyUp = up === true;
        },

        ///////////////////////////////////////////////////////////////////////////////////////
        /**
         * Compute the max amount of hands that could be purchased.
         */
        _updateMaxHands: function () {
            const leverage = this.leverage * 1;
            const margin = Math.max(0, this.availableMargin);
            this.maxHands = Math.floor(margin / (this.futuresConfig.orderUnit / leverage + this.futuresConfig.orderUnit * this.futuresConfig.openFeeRate));
        },

        ///////////////////////////////////////////////////////////////////////////////////////
        /**
         * Plus/minus hands
         */
        plusHands: function () {
            let n = this.numHands * 1;
            this.numHands = isNaN(n) ? 1 : Math.max(1, Math.round(n) + 1);
        },

        minusHands: function () {
            let n = this.numHands * 1;
            this.numHands = isNaN(n) || n <= 1 ? 1 : Math.round(n) - 1;
        },

        ///////////////////////////////////////////////////////////////////////////////////////
        /**
         * Confirm order.
         */
        doConfirmOrder: function (e) {
            const frm = $(e.target);
            if (frm.valid()) {
                this.$emit('create-order', {
                    buyUp: this.buyUp,
                    numHands: this.numHands * 1,
                    leverage: this.leverage * 1
                });
            }
        }
    }
};
</script>