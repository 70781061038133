<template>
    <form :class="{ 'd-none': !showInputs }" class="login-form" method="post" action="javascript:void(0);" @submit="createAccount">
        <div class="page-top-nav" v-if="config.phoneSupported && config.emailSupported">
            <li :class="{ active: !emailVCode }">
                <a @click="emailVCode = false" href="javascript:;">{{ $t('register.label_phone') }}</a>
            </li>
        </div>
        <div class="form-group">
            <template v-if="emailVCode">
                <!-- Email account -->
                <label v-if="!config.phoneSupported" class="form-label" for="Name">{{ $t('register.label_email') }}</label>

                <!-- Required by the interface -->
                <input v-model="account" name="Name" type="text" autocomplete="off" data-val="true" data-val-required="*" data-val-regex="*" data-val-regex-pattern="^[^@]+@[^@]{3,}$" class="form-control" :placeholder="$t('register.label_email')" />
            </template>
            <template v-else>
                <!-- Phone account -->
                <label v-if="!config.emailSupported" class="form-label" for="Name">{{ $t('register.label_phone') }}</label>

                <div class="d-flex">
                    <region-selector :regions="regions" :regionCode="regionCode" @change="onRegionChanged" />
                    <div class="flex-fill">
                        <input v-model="account" name="Name" type="text" autocomplete="off" pattern="\d*" data-val="true" data-val-required="*" data-val-regex="*" data-val-regex-pattern="^0?[1-9]\d+$" class="form-control round-right" :placeholder="$t('register.label_phone')" />
                    </div>
                </div>
            </template>
        </div>
        <div class="form-group">
            <label>{{ $t('register.label_password') }}</label>
            <div class="password-box">
                <input
                    name="Password"
                    v-model="password"
                    :type="showPassword?'text':'password'"
                    autocomplete="new-password"
                    class="form-control"
                    data-val="true"
                    data-val-required="*"
                    data-val-maxlength="*"
                    data-val-maxlength-max="32"
                    data-val-minlength="*"
                    data-val-minlength-min="2"
                    :placeholder="$t('register.label_password')"
                />
                <svg v-show="!showPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showPassword = !showPassword">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-hidePassword" />
                </svg>
                <svg v-show="showPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showPassword = !showPassword">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-showPassword" />
                </svg>
            </div>
        </div>
        <div class="form-group">
            <label>{{ $t('register.label_confirm_password') }}</label>
            <div class="password-box">
                <input
                    name="ConfirmPassword"
                    v-model="confirmPassword"
                    :type="showConfirmPassword?'text':'password'"
                    autocomplete="new-password"
                    class="form-control"
                    data-val="true"
                    data-val-required="*"
                    data-val-equalto="*"
                    data-val-equalto-other="Password"
                    data-val-maxlength="*"
                    data-val-maxlength-max="32"
                    data-val-minlength="*"
                    data-val-minlength-min="6"
                    :placeholder="$t('register.label_confirm_password')"
                />
                <svg v-show="!showConfirmPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showConfirmPassword = !showConfirmPassword">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-hidePassword" />
                </svg>
                <svg v-show="showConfirmPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showConfirmPassword = !showConfirmPassword">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-showPassword" />
                </svg>
            </div>
        </div>
        <div class="form-group" v-if="emailVCode">
            <label class="form-label" for="Phone">{{ $t('register.bind_phone') }}</label>
            <div class="d-flex">
                <region-selector :regions="regions" :regionCode="regionCode" @change="onRegionChanged" />
                <div class="flex-fill">
                    <input v-model="Phone" name="Phone" type="text" autocomplete="off" pattern="\d*" data-val="true" data-val-required="*" data-val-regex="*" data-val-regex-pattern="^0?[1-9]\d+$" class="form-control round-right" :placeholder="$t('register.label_phone')" />
                </div>
            </div>   
        </div>
        <div class="form-group">
            <label>{{ $t('register.label_referral_code') }}</label>
            <div class="code">
                <input
                    type="text"
                    name="ReferralCode"
                    v-model="refCode"
                    autocomplete="off"
                    class="form-control"
                    data-val="true"
                    data-val-required="*"
                    :placeholder="$t('register.label_referral_code')"
                />
            </div>
        </div>

        <!-- Do we need SMS verification? or just use a simple image verification code? -->
        <sms-verification v-if="config.signupVCodeRequired" :emailVCode="emailVCode" :regionCode="regionCode" :account="account" action="signup" />
        <template v-else>
            <div class="form-group">{{ $t('register.label_verification_desp') }}: {{ regionCode }} {{ account }}</div>
            <!-- <vcode-image /> -->

            <!-- Use a dummy sms code to make the API happy. -->
            <input type="hidden" name="Code" value="000000" />
        </template>
        <button type="submit" class="btn btn-submit" :class="{ 'btn-completed': completed, 'btn-loading': submitting }" :disabled="submitting || completed">
            {{ $t('register.label_register') }}
        </button>
        <div class="text-center form-group">
            <router-link to="/user/login">{{ $t('register.label_login') }}</router-link>
        </div>
    </form>
</template>
<style scoped>
    .login-page .form-control {
        background-color: #fff;
        border-radius: 0;
    }
    .login-page .form-control:focus {
        border: 1px solid #1673F9;
    }
    .login-form .text-center {
        margin-top: .75rem;
        text-align: right;
    }
    .form-group label {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #40475A;
        line-height: 1.25rem;
        margin-bottom: .5rem;
    }
    .flex-fill .form-control {
        background-color: #fff;
        border-radius: 0;
    }
    .password-box {
        position: relative;
    }
    .password-icon {
        position: absolute;
        right: 15px;
        bottom: 50%;
        transform: translateY(50%);
        cursor: pointer;
    }
</style>
<script>
import RegionSelector from '../../Components/RegionSelectorV2.vue';
import { setProfile } from 'utilities/helper';
import '@/assets/svg/v4.2/v4.2-hidePassword.svg';
import '@/assets/svg/v4.2/v4.2-showPassword.svg';

export default {
    components: { RegionSelector },
    props: ['config', 'regions', 'referral'],

    data() {
        return {
            regionCode: null,
            account: null,
            password: null,
            confirmPassword: null,
            refCode: null,
            showInputs: true,
            emailVCode:false,

            // Submit status
            submitting: false,
            completed: false,
            showPassword:false,
            showConfirmPassword:false
        };
    },

    created() {
        const config = this.config;
        if (!config || config.signupEnabled !== true) throw new Error('signupEnabled must be true when creating new accounts.');

        const regions = this.regions;
        if (!Array.isArray(regions)) throw new Error('An array of supported regions are required.');

        this.refCode = this.referral;
    },

    watch: {
        referral: function () {
            this.refCode = this.referral;
        }
    },

    mounted() {
        $.resetValidators();
    },

    methods: {

        createAccount: function (e) {
            const frm = $(e.target);
            if (!frm.valid()) return false;

            // post the server
            const data = frm.serialize();
            const self = this;
            self.submitting = true;

            $.callPostApi(self, '/api/v1/account/create', data)
                .then((json) => {
                    if (json && json.errcode === 0) {
                        // Save the current authenticated user.
                        setProfile(json.data);

                        // redirect to home page
                        self.completed = true;

                        setTimeout(function () {
                            self.$router.push('/user/verifyid');
                        }, 500);
                    }else if(json.errcode === 103){
                        // 什么也不做，保持loading状态
                    } else {
                        self.submitting = false;
                        $.top_error(json.errmsg);
                    }
                })
                .catch(function (err) {
                    self.submitting = false;
                    $.top_error(self.$t('general.operation_error'));
                })
        },

        onRegionChanged: function (val) {
            console.log(`## Region changed to ${val}`);
            this.regionCode = val;
        }
    }
};
</script>