<template>
    <div class="form-group row">
        <div class="col">
            <label class="form-label">{{ title }}</label>
            <div class="row">
                <div class="col-md-12">
                    <label class="thumbnail">
                        <div ref="imagePreview" class="img-preview">
                            <svg width="300" height="200" xmlns="http://www.w3.org/2000/svg" class="img-responsive">
                                <g style="font-size: 12px">
                                    <text
                                        x="150"
                                        y="90"
                                        text-anchor="middle"
                                        font-size="28px"
                                        font-family="robotoregular,Hiragino Sans GB,Heiti SC,Microsoft YaHei,Helvetica,Arial,serif"
                                        fill="#999">
                                        +
                                    </text>
                                    <text
                                        x="150"
                                        y="120"
                                        text-anchor="middle"
                                        font-family="robotoregular,Hiragino Sans GB,Heiti SC,Microsoft YaHei,Helvetica,Arial,serif"
                                        fill="#999">
                                        {{ title }}
                                    </text>
                                </g>
                            </svg>
                        </div>

                        <div class="d-none">
                            <input ref="fileInput" type="file" v-on:change="fileChanged" v-bind:name="name" accept="image/png, image/jpeg" />
                        </div>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['name', 'title'],

    methods: {

        fileChanged: function (e) {
            const fileInput = this.$refs.fileInput;
            const file = fileInput.files[0];
            if (file.size > 10 * 1024 * 1024) {
                // $.top_error('您选择的图片大小为 ' + Math.ceil(file.size / 1024.0) + ' KB。请选择不超过 5M 的图片文件。');
                fileInput.value = '';
                $.top_error(this.$t('idverification.err_too_big_file', { fileSize: Math.ceil(file.size / 1024.0) }));
                return false;
            }

            let imgPath = fileInput.value;
            let ext = imgPath.substring(imgPath.lastIndexOf('.') + 1).toLowerCase();
            const previewDiv = this.$refs.imagePreview;
            const img_holder = $(previewDiv);

            if (ext === 'gif' || ext === 'png' || ext === 'png' || ext === 'jpg' || ext === 'jpeg') {
                if (typeof FileReader !== 'undefined') {
                    let reader = new FileReader();
                    reader.onload = function (e) {
                        img_holder.find('svg').attr('class', 'img-responsive d-none');
                        img_holder.css('background-image', 'url(' + e.target.result + ')');
                    };

                    reader.readAsDataURL(file);
                } else {
                    console.log('This browser does not support FileReader.');
                }
            } else {
                $.top_error(this.$t('idverification.err_invalid_file_type'));

                fileInput.value = '';
                img_holder.find('svg').attr('class', 'img-responsive');
                img_holder.css('background-image', '');
            }
        }
    }
};
</script>