<template>
    <div>
        <input v-if="!supportMultipleRegions" type="hidden" name="RegionCode" v-model="localRegionCode" />

        <!-- Need to select regions -->
        <div v-else class="region-container" v-bind:class="{ 'list-open': isOpen }">
            <div class="form-control round-left area-code d-flex flex-row" @click="showSelector">
                <div class="flex-fill">{{ localRegionCode }}</div>
                <i class="dropdown-toggle"></i>
            </div>
            <div class="region-select">
                <ul>
                    <li v-for="region in regions" :key="region.areaCode" :class="{ active: localRegionCode === region.areaCode }">
                        <label @click="selectRegion">
                            {{ region.name }} <span>{{ region.areaCode }}</span>
                            <input name="RegionCode" v-model="localRegionCode" class="d-none" type="radio" v-bind:value="region.areaCode" />
                        </label>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style scoped>
/* country select */
.region-container {
    min-width: 100px;
    cursor: pointer;
    position: relative;
}
.round-left {
    border-radius: 0;
}
.region-container.list-open .area-code {
    border: 1px solid #6767ebaa;
}
.dropdown-toggle:empty::after {
    color: #1673F9;
}

.region-container .region-select {
    position: absolute;
    width: 100%;
    left: 0;
    background: #fff;
    -webkit-box-shadow: 0 0 1px 0 rgba(8, 31, 64, 0.31), 0 4px 8px -2px rgba(8, 31, 64, 0.25);
    box-shadow: 0 0 1px 0 rgba(8, 31, 64, 0.31), 0 4px 8px -2px rgba(8, 31, 64, 0.25);
    z-index: -1;
    opacity: 0;
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    min-width: 400px;
    max-height: 20rem;
    overflow-x: hidden;
    overflow-y: auto;
}
.region-container .region-select:-webkit-scrollbar-thumb {
    background: #1673F9;
}
.region-container.list-open .region-select {
    z-index: 10;
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}

.region-select li {
    list-style: none;
    cursor: pointer;
    height: 3rem;
    line-height: 3rem;
    padding: 0 1rem;
    white-space: nowrap;
    color: #263241;
}
.region-select li:hover {
    background-color: #f8f9fa;
}
.region-select li.active {
    background-color: rgb(245, 245, 245);
    /* color: rgb(201, 148, 0); */
}
.region-select li label {
    display: block !important;
}
.region-select li label span {
    float: right;
    margin-left: 20px;
    color: #9aa5b5;
}

@media (max-width: 768px) {
    .region-container .region-select {
        min-width: 300px;
    }

    .region-container .dropdown-toggle {
        padding-right: 1rem;
    }
}
</style>

<script>
export default {
    props: ['regions', 'regionCode'],

    data() {
        return { isOpen: false, localRegionCode: null };
    },

    watch: {
        localRegionCode: function (newVal, oldVal) {
            this.$emit('change', newVal);
        }
    },

    computed: {
        supportMultipleRegions: function () {
            const regions = this.regions || [];
            return regions && Array.isArray(regions) && regions.length > 1;
        }
    },

    created() {
        this.localRegionCode = this.regionCode;
        if (!this.localRegionCode) {
            const regions = this.regions || [];
            if (Array.isArray(regions) && regions.length > 0) {
                this.localRegionCode = regions[0].areaCode;
            } else {
                // Use +86 as the default region code.
                this.localRegionCode = '+86';
            }
        }
    },

    methods: {
        hideSelector: function () {
            console.log(`#### hide region selector.`);
            this.isOpen = false;
            $(window).unbind('click', this.hideSelector);
        },

        showSelector: function (event) {
            event.stopPropagation();
            this.isOpen = true;
            $(window).bind('click', this.hideSelector);
        },

        selectRegion: function (event) {
            event.stopPropagation();
            this.isOpen = false;
            $(window).unbind('click', this.hideSelector);
        }
    }
};
</script>