<template>
    <section class="page balance-page">
        <div class="top-header w-100">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="top-header-content d-flex align-items-center">
                            <div class="w-100">
                                <h1>{{ $t('assets.balance') }}</h1>
                                <div class="row balance-actions">
                                    <div class="balance-btn">
                                        <router-link to="/finance/orders">
                                            {{ $t('balance.orders_title') }}
                                        </router-link>
                                    </div>
                                    <div class="balance-btn d-none d-lg-block">
                                        <a href="javascript:;" @click="showExchangeModal">
                                            {{ $t('assets.label_exchange') }}
                                        </a>
                                    </div>
                                    <div class="balance-btn d-lg-none">
                                        <a href="javascript:;" @click="showExchangeModal">
                                            {{ $t('assets.label_exchange') }}
                                        </a>
                                    </div>
                                </div>
                                <!-- Do not display deposit/withdraw buttons when multi-coin supports are enabled. -->
                                <div v-if="bcConfig && !bcConfig.supportMultiCoinDeposits" class="mt-4">
                                    <a href="javascript:;" class="btn btn-primary ps-4 pe-4" @click="toggle_deposit()">
                                        {{ $t('general.deposit') }}
                                    </a>
                                    <router-link class="btn btn-secondary ps-4 pe-4" to="/finance/withdraw">{{ $t('general.withdraw') }}</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="asset-allocation">
                            <h2>{{ $t('coin_treasure.holding_assets') }}</h2>
                            <div class="asset-card">
                                <div class="asset-card-item" v-for="(item,index) in balance_list.slice(0,4)" :key="index">
                                    <div class="card-title">
                                        <div class="title-sign"></div>
                                        <h3>{{ getTokenName(item) }}</h3>
                                    </div>
                                    <div class="card-balance">{{ item.balance + ` ${item.currency}` }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section v-if="bcConfig" class="w-100">
            <div class="container">
                <!-- balance list here -->
                <div class="balance-tab-title">{{ $t('boption.label_balance') }}</div>
                <div class="balance-btn his-btn">
                    <router-link to="/finance/history">
                        {{ $t('balance.history_title') }}
                    </router-link>
                </div>
                <balance-list-multi-coins v-if="bcConfig.supportMultiCoinDeposits === true" :balance_list="balance_list" :futures_assets="futuresAssets" :bcConfig="bcConfig" />
                <balance-list v-else :balance_list="balance_list" :show_deposit_address="show_deposit_address" :futures_assets="futuresAssets" :bcConfig="bcConfig" />
            </div>

            <futures-stats ref="futuresStats" @balance-updated="onFuturesStatsUpdated" />
            <asset-exchange-modal ref="exchangeModal" :balances="balance_list" :bonusToken="bonusToken" @balance-updated="onBalanceUpdated" />
        </section>
    </section>
</template>

<style scoped>
.balance-page {
    background-color: #FAFAFA;
}
.w-100 h1 {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #969699;
    margin: 0;
    height: 24px;
    line-height: 24px;
    word-break: keep-all;
}
.top-header {
    margin-bottom: 20px;
    background: none;
}
.top-header .w-100{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.container {
    padding: 0;
    margin: 0 auto;
    background-color: #fff;
}
.top-header .container {
    padding: 40px 20px;
}
.row {
    margin: 0;
}
.col {
    padding: 0;
}
.top-header-content {
    padding: 0;
}
.balance-actions {
    display: flex;
    padding: 0;
}
.balance-btn {
    width: auto;
}
.balance-btn a {
    padding: 10px 20px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    border: 1px solid #1673F9;
    background-color: #fff;
    color: #1673F9;
}
.balance-btn:first-child a {
    background-color: #1673F9;
    color: #fff;
}
.balance-btn:last-child {
    padding-right: 0;
}
.asset-allocation {
    border-top: 1px solid #EDEEEF;
    margin-top: 20px;
    padding-top: 20px;
}
.asset-allocation h2 {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #242428;
    line-height: 25px;
    margin-bottom: 20px;
}
.asset-card {
    display: flex;
    justify-content: space-between;
}
.asset-card .asset-card-item {
    padding: 20px;
    border-radius: 4px;
    background-color: #F8F8F8;
    width: 20%;
}
.card-title {
    display: flex;
    align-items: center;
}
.card-title .title-sign {
    width: 8px;
    height: 8px;
    background-color: #1673F9;
}
.card-title h3 {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #242428;
    line-height: 22px;
    margin: 0;
    margin-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.card-balance {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 20px;
    color: #242428;
    line-height: 28px;
    margin-top: 4px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.container {
    padding: 0;
    position: relative;
}
.his-btn {
    position: absolute;
    right: 0;
    top: 0;
    height: 64px;
    line-height: 64px;
    padding-right: 20px;
}
.his-btn a {
    border: none;
    padding: 0;
    height: 100%;
}
.balance-tab-title {
    height: 64px;
    line-height: 64px;
    padding-left: 20px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 22px;
    color: #000922;
}
@media (max-width:1000px) {
    .asset-card {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .asset-card .asset-card-item {
        width: 45%;
    }
    .asset-card .asset-card-item:first-child,
    .asset-card .asset-card-item:nth-child(2) {
        margin-bottom: 20px;
    }
    .balance-btn a {
        padding: 8px;
    }
}
</style>

<script>
import AssetExchangeModal from './Components/AssetExchangeModal.vue';
import FuturesStats from '../Components/FuturesOpenOrderStats.vue';
import BalanceList from './BalanceComponents/_BalanceList-4.1.vue';
import BalanceListMultiCoins from './BalanceComponents/_BalanceList-MultiCoins.vue';

let g_assetTypes = [];
let g_assetTypeMap = {};

export default {
    components: { AssetExchangeModal, FuturesStats, BalanceList, BalanceListMultiCoins },

    data() {
        return {
            balance_list: [],
            bonusToken: null,
            futuresAssets: '--',

            // Config settings for blockchain
            bcConfig: null,

            // Indicates whether to display deposit addresses or not.
            show_deposit_address: false
        };
    },

    created() {
        this.initAsync();
    },

    methods: {
        initAsync: async function () {
            ///////////////////////////////////////////////////////////////////////////////////////
            // Get all asset types.
            const assetTypes = [];
            const assetTypeMap = {};

            // USDT and futures margin account will already be displayed at top
            assetTypes.push('USDT');
            assetTypes.push('FTUSDT');
            assetTypeMap['USDT'] = [];
            assetTypeMap['FTUSDT'] = [];

            // Then query blockchain config
            const resp = await $.callPostApi(this, '/api/v1/blockchain/config');
            if (!resp || !resp.data) {
                // exit for invalid config
                console.error('Invalid blockchain config.');
                return;
            }

            if (resp.data.supportedCoins) {
                $(resp.data.supportedCoins).each((index, item) => {
                    // Always use upper case
                    const upperCoin = item.currency.toUpperCase();

                    if (!assetTypeMap[upperCoin]) {
                        assetTypes.push(upperCoin);
                    }
                    assetTypeMap[upperCoin] = item;
                });
            }

            g_assetTypes = Object.freeze(assetTypes);
            g_assetTypeMap = Object.freeze(assetTypeMap);
            this.bonusToken = resp.data.bonusToken;
            this.bcConfig = Object.freeze(resp.data);

            ///////////////////////////////////////////////////////////////////////////////////////
            // Update balance list finally
            await this.syncBalancesAsync();
        },

        toggle_deposit: function () {
            this.show_deposit_address = !this.show_deposit_address;
        },
        getTokenName: function (token) {
            switch (token.currency) {
                case 'FTUSDT':
                    return this.$t('assets.label_futures_account');
                case 'INCT':
                    return this.$t('assets.label_commission');
                default:
                    return token.display_name;
            }
        },

        syncBalancesAsync: async function () {
            const self = this;
            const balances = await $.callPostApi(this, '/api/v1/balance/list');

            const map = {};
            $(balances).each((index, raw_balance) => {
                map[raw_balance.currency] = new BalanceItem(raw_balance);
            });

            // Have configured tokens display at top
            const output = [];
            $(g_assetTypes).each((index, currency) => {
                let item = map[currency];
                if (!item) {
                    item = new BalanceItem({ currency: currency, balance: 0, frozen: 0 });
                } else {
                    delete map[currency];
                }
                output.push(item);
            });

            // Any remaining balance items?
            for (let currency in map) {
                output.push(map[currency]);
            }

            self.balance_list = output;
        },

        onBalanceUpdated: function () {
            const self = this;
            this.balance_list = null;

            setTimeout(() => {
                // Update balance, and resync open futures orders.
                self.syncBalancesAsync();
                self.$refs.futuresStats.reload();
            }, 500);
        },

        showExchangeModal: function () {
            this.$refs.exchangeModal.showModal();
        },

        /**
         * Update the available amount for the futures account.
         */
        onFuturesStatsUpdated: function () {
            const stats = this.$refs.futuresStats.getStats();
            this.futuresAssets = isNaN(stats.current_balance) ? '--' : stats.current_balance.toFixed(4);
        },

    }
};

/**
 * BalanceItem
 */
function BalanceItem(balance) {
    // this.__raw_data = balance;

    // is the symbol associated to an exchange one?
    // const sym = _exchange_symbol_name_map[balance.currency];
    // const precision = sym ? sym.volumePrecision : 2;

    // hard-coded to use 4 precision
    const precision = 4;

    this.available = (balance.balance + balance.frozen).formatBalance(precision);
    this.balance = balance.balance.formatBalance(precision);
    this.frozen = (-balance.frozen).formatBalance(precision);
    // this.incentive = balance.incentive.floor(precision) * 1;

    this.display_name = balance.displayName || balance.currency;
    this.currency = balance.currency;
}
</script>